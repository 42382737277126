import React, { useEffect } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import WorkingAtFEAT from "../components/WorkingAtFEAT";
import JobPost from "../components/Jobs/JobPost";
import TrainerSlides from "../components/TrainerSlides";
import Heading from "../components/Heading";
import Trainers from "../components/Trainers";
import HeroHeader from "../components/HeroHeader";
import { useModal } from "../../provider";

export const query = graphql`
  query JobTemplateQuery($id: String!) {
    job: sanityJob(id: { eq: $id }) {
      id
      publishedAt
      title
      subtitle
      employmentType
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    image: file(relativePath: { eq: "about-us-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const applyNowId = "apply";
const workingAtFeatId = "working-at-feat";
const meetOurTrainersId = "meet-our-trainers";

const JobTemplate = (props) => {
  const { data, errors } = props;
  const job = data && data.job;

  const modal = useModal();
  useEffect(() => {
    modal.setSelectedJobSlug(job.slug.current);
    return () => modal.setSelectedJobSlug(null);
  }, [job, modal]);

  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {job && (
        <Seo
          title={job.title + " | Jobs with FEAT Fitness"}
          meta_title={job.title}
          // meta_desc={toPlainText(job._rawExcerpt)}
          // description={toPlainText(job._rawExcerpt)}
          meta_desc="Are you passionate about helping those around you achieve their goals whilst having fun and meeting other people? FEAT Fitness is often hiring as we continue to expand."
          description="Are you passionate about helping those around you achieve their goals whilst having fun and meeting other people? FEAT Fitness is often hiring as we continue to expand."
          // image={job.mainImage}
          slug={job.slug}
        />
      )}
      <main className="section">
        <HeroHeader
          title="FEAT Trainer"
          background_image={data?.image}
          options={[
            { title: "Apply Now", to: `#${applyNowId}` },
            { title: "Working at FEAT Fitness", to: `#${workingAtFeatId}` },
            { title: "Meet our Trainers", to: `#${meetOurTrainersId}` },
          ]}
        />
        <div className="my-16 max-w-4xl text-left m-auto">
          {job && <JobPost {...job} />}
        </div>
        <TrainerSlides />
        <div className="my-16 text-center">
          <Heading id={workingAtFeatId} level={2} className="mb-16">
            Working at FEAT Fitness
          </Heading>
          <WorkingAtFEAT />
        </div>
        <div className="my-16 text-center">
          <Heading id={meetOurTrainersId} level={2} className="mb-16">
            Meet our Trainers
          </Heading>
          <Trainers />
        </div>
      </main>
    </Layout>
  );
};

export default JobTemplate;
