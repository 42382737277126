import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const trainerPic1 = "../../../static/img/trainers/slides/FEATTrainers1.jpg";
const trainerPic2 = "../../../static/img/trainers/slides/FEATTrainers2.jpg";
const trainerPic4 = "../../../static/img/trainers/slides/FEATTrainers4.jpg";
const trainerPic6 = "../../../static/img/trainers/slides/FEATTrainers6.jpg";
const trainerPic7 = "../../../static/img/trainers/slides/FEATTrainers7.jpg";
const trainerPic8 = "../../../static/img/trainers/slides/FEATTrainers8.jpg";

export default function TrainerSlides() {
  return (
    <div className="grid grid-flow-row md:grid-cols-3 grid-cols-2 grid-row-3 gap-4 m-4">
      <StaticImage src={trainerPic1} />
      <StaticImage src={trainerPic2} />
      <StaticImage src={trainerPic4} />
      <StaticImage src={trainerPic6} />
      <StaticImage src={trainerPic7} />
      <StaticImage src={trainerPic8} />
    </div>
  );
}
