import React from "react";

export default function WorkingAtFEAT() {
  return (
    <div className="text-base mx-8 lg:max-w-4xl lg:mx-auto text-feat-darkgray-800">
      <p className="mb-4">
        At FEAT Fitness, we're all about the outdoors. By joining the FEAT team,
        you get to enjoy your mornings outside in the fresh air with the amazing
        sunrises doing what you love. You also get the opportunity to work with
        an amazing team of trainers from a variety of different backgrounds.
      </p>
      <p className="mb-4">
        We believe in the health, wellbeing and continual development of our
        trainers. We don't schedule trainers on that start at 6am to then have
        to work again at 6pm. We bring in experts to run education courses
        throughout the year, these vary from pre and post natal training, mental
        health first aid and equipment specific courses, we ask the trainers
        what they want to learn and work from there.
      </p>
      <p className="">
        We also love to spend our Fridays together, after our morning sessions,
        we all get into the office together to get through the last bit of admin
        for the week, and then go do a training session with each other in a
        park (or sometimes just enjoy a lunch together).
      </p>
    </div>
  );
}
