import React from "react";
import Button from "../Button";
import Heading from "../Heading";
import SanityPortableText from "../SanityPortableText/SanityPortableText";
import { useModal } from "../../../provider";

const workingAtFeatId = "working-at-feat";

function JobPost(props) {
  const { _rawBody, title, employmentType, subtitle } = props;
  const modal = useModal();
  return (
    <>
      <Heading id={workingAtFeatId} level={2} className="mb-8">
        Apply to be a {title}
      </Heading>
      <Heading level={3} className="text-feat-darkgray-200">
        {subtitle}
      </Heading>
      <Heading level={5} className="mb-6 text-feat-darkgray-200">
        {employmentType}
      </Heading>

      {_rawBody && <SanityPortableText value={_rawBody} />}

      <Button
        className="bg-feat-blue-500 text-white hover:bg-feat-blue-700 uppercase"
        onClick={() => {
          modal.openJobApplicationModal();
        }}
      >
        Apply Today
        {/* <Link to="/jobs/apply">Apply Today</Link> */}
      </Button>
    </>
  );
}

export default JobPost;
